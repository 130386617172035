import React from "react";
import AddressInputAutocomplete from "./address-input-autocomplete";
import { noop, identity } from "lodash";

const AddressInput = ({
  usingCurrentLocation,
  setUsingCurrentLocation,
  placeholder,
  onSelected,
  onClear,
  appStyles = {},
  locationBias = {},
  accurateOnly,
  showLocateMe,
  T = identity,
  onLocateMeSuccess = noop,
  onLocateMeFailure = noop,
  autoLocateMeOnMount,
  initialValue,
  previousAddresses,
}) => {
  return (
    <AddressInputAutocomplete
      usingCurrentLocation={usingCurrentLocation}
      setUsingCurrentLocation={setUsingCurrentLocation}
      disableUnderline
      focusOnMount
      inputPlaceholder={placeholder}
      inputValue={initialValue || ""}
      accessToken={REACT_APP_MAPBOX_ACCESS_TOKEN ?? ""}
      country="us"
      types={["address"]}
      onSelect={(result) => onSelected(result)}
      onClear={onClear}
      showLoader={true}
      proximity={locationBias}
      inputPaperProps={{ square: false, style: appStyles.Input }}
      suggestionsPaperProps={{ square: true }}
      showLocateMe={showLocateMe}
      T={T}
      onLocateMeSuccess={onLocateMeSuccess}
      onLocateMeFailure={onLocateMeFailure}
      autoLocateMeOnMount={autoLocateMeOnMount}
      appStyles={appStyles}
      accurateOnly={accurateOnly}
      previousAddresses={previousAddresses}
    />
  );
};

const REACT_APP_MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiZWxhZGFtcyIsImEiOiJja2dwMHoxczUwNW8wMnB0ZDJkZTRkZHlyIn0.T8wHJneScmNZHv2H9NsVbw";

export default AddressInput;
