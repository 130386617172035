import classnames from "classnames";
import _ from "lodash";
import MobileDetect from "mobile-detect";
import moment from "moment";
import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.scss";
import {
  GEOMETRIC_CENTER, MISSING_HOUSE_NUMBER, SEE_MENU_ANYWAY, TABLE_CODE
} from "../../utils/constants";
import { BUILD_TARGETS, TargetContext } from "../../target-context";
import {
    convertMapboxPlaceToUserAddress, convertPlaceToUserAddress, setDescriptionForAddress
} from "../../utils/geoLocation";
import { getGiftRelevantBranchesIds } from "../../utils/gifts";
import { getParams, navigateTo, setParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import BrandFooter from "../BrandFooter";
import GoogleMap from "../GoogleMap";
import Modal from "../Modal";
import TableCode from "../TableCode";
import AddressInputAutocomplete from "./address-input";
import AddressesList from "./AddressesList";
import AddressInput from "./AddressInput";
import BranchesList from "./BranchesList";
import * as styles from "./index.module.scss";
import SeeMenuAnyway from "./SeeMenuAnyway";

const KEY_ARROW_DOWN = 40;
const KEY_ARROW_UP = 38;
const KEY_ENTER = 13;

const LOCATION_SUGGESTION = "LOCATION_SUGGESTION";
const ADDRESS_SUGGESTION = "ADDRESS_SUGGESTION";
const PREVIOUS_ORDER_ADDRESS_SUGGESTION = "PREVIOUS_ORDER_ADDRESS_SUGGESTION";

const LOCATION_IMAGE_REF_PREFIX = "branch_";

const getLocationSuggestions = ({ branches, searchCode }) =>
  searchCode ? _.filter(branches, { searchCode }) : [];

const cleanState = {
  showSuggestions: false,
  addressSuggestions: [],
  locationSuggestions: [],
  shouldShowHouseNumberInput: false,
  houseNumberInput: "",
  userEnteredInvalidHouseNumber: false,
  addressInputValue: "",
  userAddress: null,
  loadingUserAddress: false,
  inaccurateAddress: false,
};

export default class FindLocationView extends React.Component {
  state = {
    usingCurrentLocation: false,
    userAddress:
      _.get(this.props.order, "deliveryAddress") ||
      _.get(this.props.user, "userLocatedAddress"),
    addressSuggestions: [],
    showSuggestions: false,
    highlightedSuggestionIndex: -1,
    highlightedSuggestionSelected: false,
    branchCoordsClicked: null,
    lastCanceledLocateMeRequest: null,
    locationSuggestions: getLocationSuggestions({
      branches: _.get(this.props.pageContext, "branches"),
      searchCode: _.get(this.props.order, "searchCode"),
    }),
    addressInputValue:
      (_.get(
        this.props.servingOptionDetails,
        "servingOption.forSearchOnlyByCode",
      )
        ? _.get(this.props.order, "searchCode")
        : _.get(this.props.order, "deliveryAddress.description") ||
          _.get(this.props.user, "userLocatedAddress.description")) || "",
    shouldShowHouseNumberInput: false,
    houseNumberInputValue: "",
    userEnteredInvalidHouseNumber: false,
    loadingUserAddress: false,
    modalOpen: false,
    locationChosenForModal: null,
    inaccurateAddress: false,
  };

  componentDidMount() {
    const {
      servingOptionDetails: { servingOption },
      loadBranchAvailabiltyIfNeeded,
      order,
      resetTableCode,
      location,
      availableServingOptions,
    } = this.props;
    this.md = new MobileDetect(window.navigator.userAgent);
    this.params = getParams(location);

    loadBranchAvailabiltyIfNeeded();
    if (order.tableCode) {
      resetTableCode();
    }

    const selectedTabIndex = this.params.servingOptionType
      ? _.findIndex(availableServingOptions, {
          type: this.params.servingOptionType,
        })
      : servingOption
      ? _.findIndex(availableServingOptions, {
          id: servingOption.id,
        })
      : Number(this.params.findLocationTabIndex) || 0;

    //forces lodaing the tab related data and verifying addresses for needs address serving options:
    this.onSelectTab(selectedTabIndex, true);

    if (this.params.preSelectedBranchId && servingOption.hasTableCodes) {
      this.selectLocation(this.params.preSelectedBranchId);
    }
  }

  isMobile = () => {
    if (typeof window === "undefined") {
      return true;
    }
    if (!this.md) {
      this.md = new MobileDetect(window.navigator.userAgent);
    }
    return this.md.phone();
  };

  componentDidUpdate(prevProps) {
    const {
      servingOptionDetails: { servingOption },
      branchesAvailability,
    } = this.props;

    const { branchesAvailability: prevBranchesAvailability } = prevProps;

    if (!prevBranchesAvailability && branchesAvailability) {
      if (this.params.preSelectedBranchId && servingOption.hasTableCodes) {
        this.selectLocation(this.params.preSelectedBranchId);
      }
    }
  }

  getTabStyle = (index, selectedTabIndex) => {
    const {
      appStyles: {
        backgroundColor,
        actionColor,
        accentColor,
        tabsHeaderFontSize,
        Tabs,
        TabSelected,
      },
    } = this.props;
    const tabStyle =
      index == selectedTabIndex
        ? {
            color: accentColor,
            borderColor: accentColor,
            backgroundColor,
            fontSize: tabsHeaderFontSize,
            ...Tabs,
            ...TabSelected,
          }
        : {
            backgroundColor,
            fontSize: tabsHeaderFontSize,
            ...Tabs,
          };

    return tabStyle;
  };

  onSelectTab = (userSelectedTabIndex, forceReload) => {
    const {
      pageContext: {

        businessAppConfiguration: { shouldAllowInaccurateAddress },
        branches,
      },
      order,
      user,
      availableServingOptions,
    } = this.props;

    const selectedServingOption =
      availableServingOptions[userSelectedTabIndex] ||
      availableServingOptions[0];

    console.log(
      "tab selected:",
      userSelectedTabIndex,
      _.get(selectedServingOption, "type"),
    );

    // this can cause issues :(, should be fixed until 1.9.19
    const addressDescription =
      _.get(this.state.userAddress, "description") ||
      _.get(order, "deliveryAddress.description") ||
      _.get(user, "userLocatedAddress.description") ||
      "";

    // case where the address is needed and should be verified in order to load delivery options:

    if (selectedServingOption.needsAddress) {
      if (
        this.state.userAddress &&
        _.isNumber(_.get(this.state.userAddress, "geoPoint.longitude")) &&
        _.isNumber(_.get(this.state.userAddress, "geoPoint.latitude"))
      ) {
        const isValidAddress =
          shouldAllowInaccurateAddress ||
          this.state.userAddress.isGeometricCenter;

        if (isValidAddress) {
          this.props.loadDeliveryAreas({
            lng: this.state.userAddress.geoPoint.longitude,
            lat: this.state.userAddress.geoPoint.latitude,
            servingOptionId: selectedServingOption.id,
            deliveryAddress: this.state.userAddress,
          });
        } else {
          if (_.isEmpty(_.get(this.state.userAddress, "street"))) {
            this.setState({ ...cleanState, inaccurateAddress: true });
          } else {
            this.setState({
              ...cleanState,
              inaccurateAddress: true,
              userEnteredInvalidHouseNumber: true,
              addressInputValue: addressDescription,
            });
          }
        }
      } else {
        // no user address for delivery, set clean state for any case:
        this.setState(cleanState);
      }
    } else {
      if (selectedServingOption.forSearchOnlyByCode) {
        this.setState({
          ...cleanState,
          addressInputValue: order.searchCode || "",
          locationSuggestions: getLocationSuggestions({
            branches,
            searchCode: order.searchCode,
          }),
        });
      } else {
        // const prevAddressInput = this.state.addressInputValue;
        // const nextAddressInput =
        //   currentServingOption.forSearchOnlyByCode &&
        //   !selectedServingOption.forSearchOnlyByCode
        //     ? addressDescription
        //     : prevAddressInput || "";
        // const shouldGeocodeAndHandleAddress =
        //   !_.isEmpty(nextAddressInput) && nextAddressInput !== prevAddressInput;
        // this.setState(
        //   {
        //     ...cleanState,
        //     addressInputValue: nextAddressInput,
        //     locationSuggestions:
        //       currentServingOption.forSearchOnlyByCode &&
        //       !selectedServingOption.forSearchOnlyByCode
        //         ? []
        //         : this.state.locationSuggestions,
        //   },
        //   () =>
        //     shouldGeocodeAndHandleAddress &&
        //     this.getGeocodeAndHandleAddress(nextAddressInput),
        // );
      }
    }

    this.props.setOrderServingOption(selectedServingOption);
    navigateTo(
      setParams(this.props.location, {
        servingOptionType: selectedServingOption.type,
      }),
    );
  };

  onAddressSuggestions = (addressSuggestions, locationSuggestions) => {
    const {
      servingOptionDetails: { servingOption },
    } = this.props;

    this.setState({
      inaccurateAddress: false,
      loadingUserAddress: false,
      showSuggestions: !servingOption.forSearchOnlyByCode,
      addressSuggestions: _.map(addressSuggestions, (addressSuggestion) => ({
        ...addressSuggestion,
        type: ADDRESS_SUGGESTION,
      })),
      locationSuggestions: _.map(locationSuggestions, (locationSuggestion) => ({
        ...locationSuggestion,
        type: LOCATION_SUGGESTION,
      })),
    });
  };

  handleNewUserCoords = ({ userAddress }) => {
    this.setState(
      {
        inaccurateAddress: true,
        highlightedSuggestionIndex: -1,
        addressInputValue: _.get(userAddress, "description"),
        userAddress,
        showSuggestions: false,
        loadingUserAddress: false,
      },
      this.props.enableAutoGeoLocation,
    );
  };

  handleUserSelectedAddress = (place) => {
    const {
      servingOptionDetails: { servingOption },
      pageContext: {
        businessAppConfiguration: { shouldAllowInaccurateAddress },
      },
    } = this.props;

    const isAddressInvalid =
      !shouldAllowInaccurateAddress &&
      _.get(place, "geometry.location_type") === GEOMETRIC_CENTER;

    console.log("handleUserSelectedAddress", { isAddressInvalid }, place);
    this.setState(
      {
        userAddress:
          servingOption.needsAddress && isAddressInvalid
            ? null
            : convertPlaceToUserAddress(place),
        showSuggestions: false,
        addressInputValue: place.description,
        addressSuggestions: [],
        locationSuggestions: [],
        shouldShowHouseNumberInput:
          servingOption.needsAddress && isAddressInvalid,
        houseNumberInput: "",
        userEnteredInvalidHouseNumber:
          servingOption.needsAddress && isAddressInvalid,
        loadingUserAddress: false,
      },
      () => {
        if (servingOption.needsAddress && !isAddressInvalid) {
          const userAddress = convertPlaceToUserAddress(place);
          this.props.loadDeliveryAreas({
            lng: userAddress.geoPoint.longitude,
            lat: userAddress.geoPoint.latitude,
            servingOptionId: servingOption.id,
            deliveryAddress: userAddress,
          });
        }
      },
    );
  };

  onSelectedMapboxAddress = (place) => {
    console.log({ place });
    const {
      servingOptionDetails: { servingOption },
      pageContext: {
        businessAppConfiguration: { shouldAllowInaccurateAddress },
      },
    } = this.props;

    if (place.savedAddress) {
      const userAddress = {
        ..._.pick(place, [
          "geoPoint",
          "zipCode",
          "city",
          "number",
          "street",
          "state",
          "description",
        ]),
        isGeometricCenter: true,
      };

      this.setState(
        {
          userAddress,
          showSuggestions: false,
          addressInputValue: place.description,
          addressSuggestions: [],
          locationSuggestions: [],
          shouldShowHouseNumberInput: false,
          houseNumberInput: "",
          userEnteredInvalidHouseNumber: false,
          loadingUserAddress: false,
          inaccurateAddress: false,
        },
        () => {
          if (servingOption.needsAddress) {
            this.props.loadDeliveryAreas({
              lng: userAddress.geoPoint.longitude,
              lat: userAddress.geoPoint.latitude,
              servingOptionId: servingOption.id,
              deliveryAddress: userAddress,
            });
          }
        },
      );
    } else {
      const isAccurateAddress = place.address; //&& _.get(place, "properties.accuracy") === "rooftop";

      const isAddressInvalid = !(
        shouldAllowInaccurateAddress || isAccurateAddress
      );

      console.log("handleUserSelectedAddress", { isAddressInvalid }, place);

      const userAddress =
        servingOption.needsAddress && isAddressInvalid
          ? null
          : convertMapboxPlaceToUserAddress(place);
      console.log({ userAddress });
      this.setState(
        {
          userAddress,
          showSuggestions: false,
          addressInputValue: place.description,
          addressSuggestions: [],
          locationSuggestions: [],
          shouldShowHouseNumberInput:
            servingOption.needsAddress && isAddressInvalid,
          houseNumberInput: "",
          userEnteredInvalidHouseNumber:
            servingOption.needsAddress && isAddressInvalid,
          loadingUserAddress: false,
          inaccurateAddress: !isAccurateAddress,
        },
        () => {
          if (servingOption.needsAddress && !isAddressInvalid) {
            this.props.loadDeliveryAreas({
              lng: userAddress.geoPoint.longitude,
              lat: userAddress.geoPoint.latitude,
              servingOptionId: servingOption.id,
              deliveryAddress: userAddress,
            });
          }
        },
      );
    }
  };

  handleAddressInputReset = () => {
    console.log("input reset");
    this.setState({
      showSuggestions: false,
      addressSuggestions: [],
      locationSuggestions: [],
      addressInputValue: "",
      shouldShowHouseNumberInput: false,
      userEnteredInvalidHouseNumber: false,
      userAddress: null,
      loadingUserAddress: false,
      inaccurateAddress: false,
    });
  };

  handleLocateMeError = (error) => {
    console.log({ error });
    this.setState({
      showSuggestions: false,
      loadingUserAddress: false,
    });
  };

  selectLocation = (locationId, disableOrder) => {
    this.handleLocationSelected(locationId, disableOrder)();
  };

  handleLocationSelected = (locationId, disableOrder) => () => {
    const {
      servingOptionDetails: { servingOption },
      branchesAvailability,
    } = this.props;

    const { userAddress } = this.state;
    const selectedBranch = _.get(branchesAvailability, locationId);

    const defaultTip = _.get(
      selectedBranch,
      `servingOptionTypeToDefaultSelectedTipOption[${servingOption.type}]`,
    );
    console.log({ defaultTip }, servingOption.offersTip);
    console.log(_.get(selectedBranch, "branch.disableDeliveryTip"));
    this.props.setUserSelectedLocationAndServingOption({
      servingOption,
      locationId,
      defaultTip:
        (defaultTip && servingOption.offersTip) ||
        (servingOption.needsAddress &&
          !_.get(selectedBranch, "branch.disableDeliveryTip"))
          ? defaultTip
          : 0,
      firstAvailableDate: _.get(selectedBranch, "availableFrom"),
      userAddress: userAddress,
    });
    const { hasTableCodes } = servingOption;
    if (disableOrder) {
      this.openModalForBranch({
        branchId: locationId,
        modalType: SEE_MENU_ANYWAY,
      });
    } else if (
      hasTableCodes &&
      !_.isEmpty(_.get(branchesAvailability, `${locationId}.tableCodeRanges`))
    ) {
      console.log("open table code modal");
      this.openModalForBranch({
        branchId: locationId,
        modalType: TABLE_CODE,
      });
    }
  };

  getPreviousOrderAddressSuggestions = () =>
    _.map(
      _.get(
        this.props.user,
        "userDetails.data.seenAddresses",
        (seenAddress) => ({
          ...setDescriptionForAddress(seenAddress),
          type: PREVIOUS_ORDER_ADDRESS_SUGGESTION,
        }),
      ),
    );

  getSuggestionList = () =>
    _.isEmpty(this.state.addressInputValue)
      ? this.getPreviousOrderAddressSuggestions()
      : _.concat(this.state.addressSuggestions, this.state.locationSuggestions);

  handleKeyDown = (e) => {
    const suggestionList = this.getSuggestionList();
    const { highlightedSuggestionIndex } = this.state;
    if (e.keyCode === KEY_ARROW_UP && highlightedSuggestionIndex > 0) {
      const highlightedSuggestion =
        suggestionList[highlightedSuggestionIndex - 1];
      const addressInputValue =
        highlightedSuggestion.type === LOCATION_SUGGESTION
          ? highlightedSuggestion.address
          : highlightedSuggestion.description;
      this.setState({
        highlightedSuggestionIndex: highlightedSuggestionIndex - 1,
        addressInputValue,
      });
    } else if (
      e.keyCode === KEY_ARROW_DOWN &&
      highlightedSuggestionIndex < suggestionList.length - 1
    ) {
      const highlightedSuggestion =
        suggestionList[highlightedSuggestionIndex + 1];
      const addressInputValue =
        highlightedSuggestion.type === LOCATION_SUGGESTION
          ? highlightedSuggestion.address
          : highlightedSuggestion.description;
      this.setState({
        highlightedSuggestionIndex: highlightedSuggestionIndex + 1,
        addressInputValue,
      });
    } else if (e.keyCode === KEY_ENTER && highlightedSuggestionIndex > -1) {
      const highlightedSuggestion = _.get(
        suggestionList,
        `[${highlightedSuggestionIndex}]`,
      );
      if (highlightedSuggestion) {
        if (highlightedSuggestion.type === LOCATION_SUGGESTION) {
          // TODO: check that this is really branchId
          this.handleLocationSelected(highlightedSuggestion.branchId);
        } else {
          if (highlightedSuggestion.error) {
            highlightedSuggestion.error === MISSING_HOUSE_NUMBER &&
              this.setState({
                shouldShowHouseNumberInput: true,
              });
          } else {
            this.setState({
              highlightedSuggestionSelected: true,
            });
          }
        }
      }
    }
  };

  resetHighlightedAddressSelected = () =>
    this.setState({
      highlightedSuggestionSelected: false,
      loadingUserAddress: false,
    });

  onChangeAddressInput = (address) =>
    this.setState({
      highlightedSuggestionIndex: -1,
      addressInputValue: address,
      loadingUserAddress: false,
    });

  onBranchLocationClick = (coords) => (e) => {
    e.stopPropagation();
    e.preventDefault();
    !_.isEqual(this.state.branchCoordsClicked, coords) &&
      this.setState({
        branchCoordsClicked: coords,
        loadingUserAddress: false,
      });
  };

  showHouseNumberInput = (addressInputValue) => () =>
    this.setState({
      shouldShowHouseNumberInput: true,
      addressInputValue,
    });

  geocode = (address) =>
    new Promise((resolve, reject) => {
      try {
        const Geocoder = this.props.google.isLoaded && new window.google.maps.Geocoder();

        Geocoder.geocode({ address }, (geocode, status) => {
          this.setState({ loadingUserAddress: false }, () => {
            if (status === "OK" && !this.cancelGeocoding) {
              return resolve(geocode);
            } else {
              return resolve(null);
            }
          });
        });
      } catch (error) {
        this.setState({ loadingUserAddress: false }, reject);
      }
    });

  getGeocodeAndHandleAddress = (addressInputValue) => {
    const {
      pageContext: {
        businessAppConfiguration: { shouldAllowInaccurateAddress },
      },
      servingOptionDetails: {
        servingOption: { needsAddress },
      },
    } = this.props;
    console.log("getGeocodeAndHandleAddress...");
    if (
      typeof addressInputValue === "string" &&
      addressInputValue.trim() &&
      this.props.google
    ) {
      console.log("getGeocodeAndHandleAddress...google loaded...");
      this.setState({ loadingUserAddress: true }, () =>
        this.geocode(addressInputValue)
          .then((geocode) => {
            console.log("getGeocodeAndHandleAddress...geocoded...");
            if (geocode) {
              const isAddressInvalid =
                !shouldAllowInaccurateAddress &&
                _.get(geocode, "[0].geometry.location_type") ===
                  GEOMETRIC_CENTER;
              const description = isAddressInvalid
                ? addressInputValue
                : geocode[0].formatted_address;
              console.log("getGeocodeAndHandleAddress:", { isAddressInvalid });
              if (isAddressInvalid && needsAddress) {
                this.setState({
                  ...cleanState,
                  addressInputValue: description,
                  inaccurateAddress: true,
                });
              } else {
                this.handleUserSelectedAddress({
                  ...geocode[0],
                  description,
                  inaccurateAddress: false,
                });
              }
            } else {
              this.setState({
                loadingUserAddress: false,
              });
            }
          })
          .catch((error) => {
            console.error(error);
            this.setState({
              loadingUserAddress: false,
            });
          }),
      );
    } else {
      this.setState({
        loadingUserAddress: false,
      });
    }
  };

  setAddressWithHouseNumber = (houseNumber) => {
    const address = _.split(this.state.addressInputValue, ",");
    address[0] = _.replace(address[0], /[0-9]/g, "") + " " + houseNumber;
    const addressInputValue = _.join(address, ",");

    this.getGeocodeAndHandleAddress(addressInputValue);
  };

  openModalForBranch = ({ branchId, modalType }) =>
    this.setState({
      modalType,
      modalOpen: true,
      locationChosenForModal: branchId,
    });

  onCloseModal = () =>
    this.setState({
      locationChosenForModal: null,
      modalOpen: false,
    });

  setUsingCurrentLocation = value => {
    this.setState({ usingCurrentLocation: value });
  };

  render() {
    const {
      T,
      pageContext: {
        branches,
        orderUrl,
        business: { currencySymbol },
        businessAppConfiguration,
      },
      appStyles,
      servingOptionDetails: { servingOption, branch },
      giftRedeem,
      location,
      user,
      locations,
      data,
      branchesAvailability,
      deliveryOptions,
      isSSR,
      setSearchCode,
      setTableCode,
      availableServingOptions,
      order: { tableCode },
    } = this.props;

    const previousAddresses = user?.userDetails?.data?.seenAddresses;
    const { forSearchOnlyByCode } = servingOption;

    const metric = _.get(businessAppConfiguration, "metric");

    const { shouldAllowInaccurateAddress } = businessAppConfiguration;

    this.params = getParams(location);

    const isDuringGiftRedeem = giftRedeem.selectedGift;

    const selectedTabIndex = servingOption
      ? _.findIndex(availableServingOptions, {
          id: servingOption.id,
        })
      : Number(this.params.findLocationTabIndex) || 0;

    const needsAddress = servingOption.needsAddress;

    const availableBranchIds = _.keys(branchesAvailability);
    const availableBranches = _.filter(branches, (branch) =>
      _.includes(availableBranchIds, branch.id),
    );

    const userLocatedAddress = _.get(this.props.user, "userLocatedAddress");
    const userLocatedAddressTimeStamp = _.get(userLocatedAddress, "timestamp");

    const isUserLocatedAddressIsRelevant =
      userLocatedAddress && userLocatedAddressTimeStamp
        ? moment()
            .subtract(2, "minutes")
            .isBefore(userLocatedAddressTimeStamp)
        : false;

    const branchesWithCode = _.filter(availableBranches, "searchCode");

    const dontUseMapbox =
      businessAppConfiguration.useGoogleMapsForGeocoding ||
      appStyles.locale !== "en-US" ||
      forSearchOnlyByCode;
      
    
    const customTarget = !_.isEmpty(giftRedeem.steps) && giftRedeem.steps[0].context.pathname;
    return (
      <AppContainer.ResponsiveWrapper>
        <AppContainer.Content
          appStyles={appStyles}
          classNames={classnames(styles.Content, appStyles.rtl && styles.RTL)}
        >
          <Modal
            open={this.state.modalOpen}
            onClose={this.onCloseModal}
            appStyles={appStyles}
            center
            style={
              this.state.modalType === TABLE_CODE && {
                width: "80%",
              }
            }
          >
            {this.state.modalType === TABLE_CODE ? (
              <TableCode
                T={T}
                appStyles={appStyles}
                onClose={this.onCloseModal}
                branchAvailability={
                  _.find(branchesAvailability, {
                    branchId: this.state.locationChosenForModal,
                  }) ||
                  _.find(branchesAvailability, {
                    branchId: availableBranchIds[0],
                  })
                }
                tableCode={tableCode}
                setTableCode={setTableCode}
                onSetTableCode={(tableCode) =>
                  this.setState({ modalOpen: false }, () => {
                    console.log(
                      setParams(customTarget || "/order", {
                        branchId: branch.id,
                        branchName: branch.name,
                        tableCode,
                      }),
                    );
                    
                    navigateTo(
                      setParams(customTarget || "/order", {
                        branchId: branch.id,
                        branchName: branch.name,
                        tableCode,
                      }),
                    );
                  })
                }
              />
            ) : (
              <SeeMenuAnyway
                params={this.params}
                T={T}
                appStyles={appStyles}
                branchAvailability={
                  _.find(branchesAvailability, {
                    branchId: this.state.locationChosenForModal,
                  }) ||
                  _.find(branchesAvailability, {
                    branchId: availableBranchIds[0],
                  })
                }
                servingOptionTag={_.get(
                  availableServingOptions,
                  `[${selectedTabIndex}].servingOptionTag`,
                )}
              />
            )}
          </Modal>
          {!isSSR && (
            <Tabs
              style={{
                backgroundColor: appStyles.backgroundColor,
              }}
              className={classnames(
                "FlexTabs",
                styles.ServingOptions,
                isDuringGiftRedeem && styles.WithGiftInstruction,
              )}
              selectedIndex={Number(selectedTabIndex)}
              onSelect={this.onSelectTab}
            >
              <TabList>
                {_.map(availableServingOptions, (option, index) => (
                  <Tab
                    key={option.id}
                    style={this.getTabStyle(index, selectedTabIndex)}
                  >
                    {T(option.servingOptionTag)}
                  </Tab>
                ))}
              </TabList>
              {!dontUseMapbox && (
                <>
                  <AddressInputAutocomplete
                    usingCurrentLocation={this.state.usingCurrentLocation}
                    setUsingCurrentLocation={this.setUsingCurrentLocation}
                    onSelected={this.onSelectedMapboxAddress}
                    placeholder={
                      needsAddress
                        ? T("Enter address with street number")
                        : forSearchOnlyByCode
                        ? `${T("Enter your code")} `
                        : `${T("Enter city, address or zip")} `
                    }
                    T={T}
                    appStyles={appStyles}
                    locationBias={_.get(availableBranches, `[0].geoPoint`)}
                    showLocateMe={!needsAddress && !forSearchOnlyByCode}
                    onLocateMeFailure={this.handleLocateMeError}
                    onLocateMeSuccess={this.handleNewUserCoords}
                    autoLocateMeOnMount={
                      branches.length > 2 &&
                      !needsAddress &&
                      !forSearchOnlyByCode &&
                      !appStyles.disableAutoFindLocation &&
                      user.autoGeoLocation &&
                      !isUserLocatedAddressIsRelevant
                    }
                    shouldAllowInaccurateAddress={shouldAllowInaccurateAddress}
                    shouldShowHouseNumberInput={
                      this.state.shouldShowHouseNumberInput
                    }
                    setAddressWithHouseNumber={this.setAddressWithHouseNumber}
                    userEnteredInvalidHouseNumber={
                      this.state.userEnteredInvalidHouseNumber
                    }
                    initialValue={this.state.addressInputValue}
                    accurateOnly={needsAddress}
                    onClear={this.handleAddressInputReset}
                    previousAddresses={previousAddresses}
                  />
                </>
              )}
              {dontUseMapbox && (
                <AddressInput
                  placeholder={
                    needsAddress
                      ? T("Enter address with street number")
                      : forSearchOnlyByCode
                      ? `${T("Enter your code")} `
                      : `${T("Enter city, address or zip")} `
                  }
                  T={T}
                  ref={(addressInput) => {
                    this.addressInput = addressInput;
                  }}
                  noSearch={forSearchOnlyByCode}
                  locationBias={_.get(availableBranches, `[0].geoPoint`)}
                  showsAddressesSuggestions={this.state.showSuggestions}
                  types={servingOption.needsAddress && ["address"]}
                  google={this.props.google}
                  showLocateMe={!needsAddress && !forSearchOnlyByCode}
                  onResults={this.onAddressSuggestions}
                  onLoadingAddresses={this.onLoadingAddressesSuggestions}
                  onLocateMeError={this.handleLocateMeError}
                  onLocateMeSuccess={this.handleNewUserCoords}
                  onReset={this.handleAddressInputReset}
                  appStyles={appStyles}
                  autoLocateMeOnMount={
                    branches.length > 2 &&
                    !needsAddress &&
                    !forSearchOnlyByCode &&
                    !appStyles.disableAutoFindLocation &&
                    user.autoGeoLocation &&
                    !isUserLocatedAddressIsRelevant
                  }
                  handleKeyDown={this.handleKeyDown}
                  onChangeAddressInput={this.onChangeAddressInput}
                  addressInputValue={this.state.addressInputValue}
                  shouldShowHouseNumberInput={
                    this.state.shouldShowHouseNumberInput
                  }
                  setAddressWithHouseNumber={this.setAddressWithHouseNumber}
                  userEnteredInvalidHouseNumber={
                    this.state.userEnteredInvalidHouseNumber
                  }
                  branchesWithCode={branchesWithCode}
                  forSearchOnlyByCode={forSearchOnlyByCode}
                  setSearchCode={setSearchCode}
                  shouldAllowInaccurateAddress={shouldAllowInaccurateAddress}
                />
              )}
              {this.state.inaccurateAddress && needsAddress && (
                <p>{T("Sorry, this address is not accurate enough")}</p>
              )}
              {dontUseMapbox &&
                this.state.showSuggestions &&
                !this.state.shouldShowHouseNumberInput && (
                  <AddressesList
                    T={T}
                    accurateOnly={needsAddress}
                    google={this.props.google}
                    places={this.state.addressSuggestions}
                    appStyles={appStyles}
                    geocodeOnSelect
                    onSelected={this.handleUserSelectedAddress}
                    classNames={styles.AddressList}
                    highlightedSuggestionIndex={
                      this.state.highlightedSuggestionIndex
                    }
                    onMouseOverAddressIndex={(index) =>
                      this.setState({
                        highlightedSuggestionIndex: index,
                        addressInputValue: _.get(
                          this.state.addressSuggestions,
                          `[${index}].description`,
                        ),
                      })
                    }
                    resetHighlightedAddressSelected={
                      this.state.highlightedSuggestionSelected &&
                      this.resetHighlightedAddressSelected
                    }
                    showHouseNumberInput={this.showHouseNumberInput}
                  />
                )}
              {!this.state.showSuggestions &&
                !this.state.shouldShowHouseNumberInput &&
                _.map(availableServingOptions, (servingOption, index) => (
                  <TabPanel key={index} className={styles.BranchesList}>
                    {!this.state.showSuggestions && !this.props.isSSR ? (
                      <TargetContext.Consumer>
                        {buildTarget => (
                          <BranchesList
                            usingCurrentLocation={this.state.usingCurrentLocation}
                            params={this.params}
                            key={index}
                            onSelectedBranch={this.handleLocationSelected}
                            google={this.props.google}
                            showWhenNoCoords={!needsAddress}
                            userCoords={_.get(this.state, "userAddress.geoPoint")}
                            locations={locations}
                            deliveryOptions={deliveryOptions}
                            branches={branches}
                            branchesFilter={
                              forSearchOnlyByCode
                                ? _.map(this.state.locationSuggestions, "id")
                                : isDuringGiftRedeem &&
                                  getGiftRelevantBranchesIds(
                                    giftRedeem.selectedGift,
                                  )
                            }
                            backTo={buildTarget === BUILD_TARGETS.WEBSITE ? "website.locations" : null}
                            customTarget={
                              buildTarget === BUILD_TARGETS.WEBSITE ? new URL("/order", orderUrl).toString() :
                                (!_.isEmpty(giftRedeem.steps) &&
                                  giftRedeem.steps[0].context.pathname)
                            }
                            appStyles={appStyles}
                            T={T}
                            reloadBranchAvailabilty={
                              this.props.loadBranchAvailabiltyIfNeeded
                            }
                            servingOption={servingOption}
                            currencySymbol={currencySymbol}
                            metric={metric}
                            onBranchLocationClick={this.onBranchLocationClick}
                            branchesAvailability={branchesAvailability}
                            userEnteredInvalidHouseNumber={
                              this.state.userEnteredInvalidHouseNumber
                            }
                            loadingUserAddress={this.state.loadingUserAddress}
                          />
                        )}
                      </TargetContext.Consumer>
                    ) : (
                      <div />
                    )}
                  </TabPanel>
                ))}
            </Tabs>
          )}
        </AppContainer.Content>
        <AppContainer.SecondaryContent>
          {!this.props.isSSR && !this.isMobile() && (
            <GoogleMap
              google={this.props.google}
              center={
                !_.isEmpty(this.state.coords) && !this.state.showSuggestions
                  ? {
                      lat: this.state.coords.latitude,
                      lng: this.state.coords.longitude,
                    }
                  : {
                      lat: _.get(branches, "[0].geoPoint.latitude"),
                      lng: _.get(branches, "[0].geoPoint.longitude"),
                    }
              }
              height={"calc(100vh - 35px - 60px)"}
              locations={
                servingOption.needsAddress
                  ? _.map(deliveryOptions, (item) => item.branch)
                  : availableBranches
              }
              locationCoordsClicked={
                !_.isEmpty(this.state.branchCoordsClicked) && {
                  lat: this.state.branchCoordsClicked.latitude,
                  lng: this.state.branchCoordsClicked.longitude,
                }
              }
              iconPath={appStyles.iconPath}
              servingOption={servingOption.type || "pickup"}
            />
          )}
        </AppContainer.SecondaryContent>
        <BrandFooter
          T={T}
          appStyles={appStyles}
          backPath="/find-location"
          params={getParams(location)}
        />
      </AppContainer.ResponsiveWrapper>
    );
  }
}
